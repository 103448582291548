<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel title="Exécution" :visible="executionPanelVisible" @expand="executionPanelVisible=true" @collapse="executionPanelVisible=false">
            <table style="width:100%">
                <tr class="even">
                    <td @click="edtCartouche.controle=null">contrôle</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.controle" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.utilisationFenetre=null">utilisation de la fenêtre</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.utilisationFenetre" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.difficultesTechniques=null">difficultés techniques</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.difficultesTechniques" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.tricks=null">tricks</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.tricks" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.particularitesMultiLignes=null">particularité des multi-lignes</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.particularitesMultiLignes" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.timing=null">timing</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.timing" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.espacement=null">espacement</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.espacement" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
            </table>
        </collapse-panel>
        <slot name="between-execution-and-content"></slot>
        <collapse-panel title="Contenu" :visible="contentPanelVisible" @expand="contentPanelVisible=true" @collapse="contentPanelVisible=false" >
            <table style="width:100%">
                <tr class="even">
                    <td @click="edtCartouche.complexite=null">complexité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.complexite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.originalite=null">originalité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.originalite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.creativite=null">créativité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.creativite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.continuite=null">continuité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.continuite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
            </table>
        </collapse-panel>
        <slot name="between-content-and-comments"></slot>
         <collapse-panel title="Notes" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments" @change="emit_change"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

export default {
    components:{ CollapsePanel },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
        /*executionPanelVisible: { type:Boolean, default: true},
        contentPanelVisible: { type:Boolean, default: true},
        commentsPanelVisible: {type: Boolean, default: true},*/
    },
    data(){
        return {
            edtCartouche: this.value,
        };
    },
    computed:{
      ...mapGetters('config', [ 'cfgRoutineNoteEditor_executionPaneVisible', 'cfgRoutineNoteEditor_contentPaneVisible', 'cfgRoutineNoteEditor_commentsPaneVisible']),

      executionPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_executionPaneVisible(newVal); }
        },
        contentPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_contentPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_contentPaneVisible(newVal); }
        },
        commentsPanelVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_commentsPaneVisible(newVal); }
        },
    },
    methods:{
        ...mapMutations('config', [ 'setCfgRoutineNoteEditor_executionPaneVisible', 'setCfgRoutineNoteEditor_contentPaneVisible', 'setCfgRoutineNoteEditor_commentsPaneVisible']),
        emit_change(){
            this.$emit('change');
        }
    },
    /*watch:{
        value: function(newValue){
            this.edtCartouche = newValue;
        }
    }*/
}
</script>

<style scoped>
/*    
    td{ padding-left: 10px; padding-right: 5px;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
*/
</style>
<style>
    td.note-detail{ display:flex; justify-content: flex-end;}
</style>