<template>
    <div>
        <div class="renderer">
            <slot name="Start" ></slot>
        </div>
        <div class="renderer">
            <div class="global">{{ arrondir(noteGlobale.noteValue) }}</div>
        </div>
        <div class="renderer">
            <slot name="Middle">
                <b-button class="nextStep" variant="primary" size="sm" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
        <div class="renderer" v-if="cfgVerboseNoteSummary">
            <div v-for="judgeNote in notesPerJudges" :key="judgeNote.judge_id" style="display:none">
                <div class="judgeNote">
                    <div class="judgeName">{{ judgeNote.JUDGE.PEOPLE.shortname}}</div>
                    <div class="note">{{ judgeNote.isAverage ? 'A' : judgeNote.noteEntry }}</div>
                    <div class="penalties">{{judgeNote.penalties && judgeNote.penalties != null && judgeNote.penalties != '' ? 'Avec pénalité' : 'Sans pénalité' }}</div>
                    <!--<div class="note">{{ judgeNote.noteValue }}</div>-->
                    <div>
                        <b-button
                            v-if="userCanEditNote"
                            @click="modifyJudgeNote(judgeNote.judge_id)"
                            class="modifyJudgeNote"
                            variant="secondary"
                        >
                            Modifier
                        </b-button>
                    </div>
                </div>
            </div>

            <b-table small hover :items="notesPerJudges" :fields="verbodeModeTableColumns" class="verboseNotes" responsive thead-class="headerClass">
                <template #cell(judge)="data">
                  <span class="judge" :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.JUDGE.PEOPLE.shortname }}</span>
                </template>
                <template #cell(note)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}"><b>{{ arrondir(data.item.noteValue ) }}</b></span>
                </template>
                <template #cell(penalty)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.penalty || '0'}}</span>
                </template>
                <template #cell(grossTotal)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.isAverage ? "A" : data.item.noteEntry }}</span>
                </template>
                <template #cell(editBtn)="data">
                    <b-button
                            @click="modifyJudgeNote(data.item.judge_id)"
                            class="modifyJudgeNote"
                            variant="secondary"
                        >
                            Modifier
                        </b-button>

                </template>
            </b-table>
        </div>
        <div class="renderer">
            <slot name="End"></slot>
        </div>
    </div>
</template>

<script>
import { RST_COMPULSORY_NOTE } from "@/LocalDB";


export default {
    props: {
    },
    data(){
        return {
            noteGlobale: '',
            notesPerJudges: [],
            userCanEditNote: false,
            verbodeModeTableColumns: [],
        };
    },
    computed:{
//        ...mapGetters('currentState', ['currentCategory', 'currentCompetitor', 'currentFigure'] ),
    },
    methods:{
        async refresh(){
            console.log('refresh', this.cfgVerboseNoteSummary, this.currentEvent);
            this.noteGlobale = RST_COMPULSORY_NOTE.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where("event", this.currentEvent.event)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .where(n => n.judge_id == null)
                                .where('compulsory_id', this.currentEvent.current_compulsory_id)
                                .first();
            console.log('noteGlobale', this.noteGlobale);
            if(this.cfgVerboseNoteSummary){
                this.userCanEditNote = await this.userCan('EDIT_JUDGE_NOTE_ON_NOTE_ENTRY_SUMMARY');

                this.verbodeModeTableColumns = [
                    { key: "judge", sortable: false, label:"Juge" },
                    { key: "note", sortable:true, label:"Total" },
                    { key: "penalty", sortable:true, label:"Pénal."},
                    { key: "grossTotal", sortable:true, label:"Tot. brut"}
                ];
                if(this.userCanEditNote)
                {
                    this.verbodeModeTableColumns.push({ key: "editBtn", sortable:false, label:""});
                }

                this.notesPerJudges = RST_COMPULSORY_NOTE.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .where(n => n.judge_id != null)
                                .where('compulsory_id', this.currentEvent.current_compulsory_id)
                                .get();
            }
            //console.log('refresh => ', { noteGlobale : this.noteGlobale, verbose : this.cfgVerboseNoteSummary, noteArrondie : this.arrondir(this.noteGlobale), details: this.notesPerJudges });
        },
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },
        nextStep(){
            this.$emit("nextStep");
        },
    },
    async mounted(){
        await this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        /*flex-wrap: wrap;*/
        font-size:1.2em; border: solid 1px darkgray; color:darkgray;
        width:100px; height:70px;
        margin: 20px 10px;
        padding: 2px 0px;
        font-size:0.7rem;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2em;
            /*border:solid 4px darkgreen;*/
            /*background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .modifyJudgeNote{
        font-size:0.7em;
        margin: 2px;
    }
</style>
