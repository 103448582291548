<template>
    <div>
        <event-header
          :showElementsToCorrect="false"
          :showBackButton="!currentEvent.isStarted"
        >
        </event-header>

        <div v-if="currentEvent.isCompleted">
          <h4>Epreuve terminée !               
            <info-helper>
                    En cliquant sur le bouton "Libérer le terrain et continuer" (vert) ci-dessous, vous libérez le terrain utilisé pour l'epreuve qui vient de se terminer (terrain froid)
                    et vous retournez au dashboard de suivi des épreuves.<br/>
                    <br/>
                    Si au contraire vous cliquez sur le bouton "Continuer sans libérer le terrain" (bleu) ci-dessous, vous retournez au dashboard de suivi des épreuves sans libérer le terrain (qui est maintenu à l'état "chaud").
            </info-helper> 
          </h4>
            <form-action-buttons-area style="margin-top:5px;">
              <div>
                <b-button variant="success" size="xl" @click="backToDashboard(true)">Libérer le terrain et continuer
                </b-button>
              </div>
              <div>
                <b-button variant="primary" size="xl" @click="backToDashboard(false)">Continuer sans libérer le terrain</b-button>
              </div>
            </form-action-buttons-area>
            <br />
            <h4>Résultats de l'épreuve</h4>
            <detailed-results
                ref = "results"
                :category = "currentCategory"
                :event = "currentEvent"
                :isOptionAndSortPanelVisible="false"
            />
        </div>

        <b-container fluid v-else>
            <hr v-if="false" />

            <b-container fluid class="cartouche">
            <div class="col-xs-6 competitor">
                <div class="8optional">Compétiteur</div>
                <div class="name">{{ currentCompetitor.name }}</div>
                <b-button variant="link" size="sm" v-if="isSkippableCompetitor" class="button" @click="skipCompetitor">Passer au suivant</b-button>
            </div>
            <hr v-if="false && !((currentEvent.current_step === null) || (currentEvent.current_step === '')) && currentStepLabels.isUsable" />
            <div class="col-xs-6 note" v-if="!((currentEvent.current_step === null) || (currentEvent.current_step === '')) && currentStepLabels.isUsable">
                <div class="compulsory-number" v-if="currentStepLabels.label1 != ''">{{ currentStepLabels.label1 }}</div>
                <div class="name">{{ currentStepLabels.label2 }}</div>
            </div>
</b-container>
            <hr v-if="false" />

            <div class="Notesummary" v-if="showSummary">
                <p class="error" v-if="errorAllA">
                    <b>Impossible de calculer la note totale pour cette figure !</b><br/>
                    Tous les juges ont mit "A".<br/>
                    La figure doit être notée à nouveau.<br/>
                    <b-button @click="resetCurrentFigureNotes">Recommencer la figure</b-button>
                </p>
                <div v-else>
                    <competitor-event-result
                        v-if="(currentEvent.current_step === null) || (currentEvent.current_step === '')"
                        @nextStep = "nextCompetitorOrEndOfEvent"
                    >
                    </competitor-event-result>
                    <div v-else>
                        <compulsory-note-result
                            v-if="isCurrentlyCompulsories === true"
                            @modifyJudgeNote = "modifyJudgeNote"
                            @nextStep = "nextStep"
                        >
                        </compulsory-note-result>
                        <routine-note-result
                            v-if="isCurrentlyRoutine === true"
                            @modifyJudgeNote = "modifyJudgeNote"
                            @nextStep = "nextStep"
                        >
                        </routine-note-result>
                        <ballet-note-result
                            v-if="isCurrentlyBallet === true"
                            @modifyJudgeNote = "modifyJudgeNote"
                            @nextStep = "nextStep"
                        >
                        </ballet-note-result>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="judge" v-if="showNoteEditor">
                    <p class="note-edit-instructions" v-if="isCurrentlyCompulsories">Entrez votre note, <b>{{ currentJudge.firstname }}</b>, ou cochez la case "average" si vous n'avez pas matière à donner une note équitable puis cliquez sur valider.</p>
                    <p class="note-edit-instructions" v-else>Entrez vos 2 notes, <b>{{ currentJudge.firstname }}</b> puis cliquez sur valider.</p>
                    <compulsory-note-editor
                        v-if="isCurrentlyCompulsories === true"
                        @change="saveCompulsoryNote"
                        @cancel="cancelNote"
                        :possiblePenalties="compulsoriesPenalties" />
                    <routine-note-editor
                        v-if="isCurrentlyRoutine === true"
                        @change="saveRoutineNote"
                        @cancel="cancelNote"
                        :executionPound="0.6/*currentEvent.RULE.poundOfRoutineExecution*/"
                        :contentPound="0.4/*currentEvent.RULE.poundOfRoutineContent*/"
                        :possiblePenalties="routinePenalties"
                    />
                    <ballet-note-editor
                        v-if="isCurrentlyBallet === true"
                        @change="saveBalletNote"
                        @cancel="cancelNote"
                        :executionPound="0.4/*currentEvent.RULE.poundOfBalletExecution*/"
                        :choreoPound="0.6/*currentEvent.RULE.poundOfBalletChoreo*/"
                        :possiblePenalties="balletPenalties" />
                </div>
                <div v-else>
                  <div v-if="showJudgeSelector">
                    <p>A qui le tour de noter ?</p>
                    <div class="judgesSelector">
                        <b-button v-for="judge in judgesList" :key="judge.id"
                            :disabled="judge.isCompleted"
                            :class="{'enabled' : !judge.isCompleted}"
                            @click="setCurrentJudge(judge)"
                        >
                        <div class="nom">{{ judge.shortname }}</div>
                        <div class="shadow" v-if="judge.isShadow">(ombre)</div>
                        </b-button>
                    </div>
                    <b-button variant="secondary" @click="showJudgeSelector=false">Retour en mode "attente"</b-button>
                  </div>
                  <div v-else>
                    <p>En attende de la notation des autres juges...</p>
                    <p>En cas de problème de saisie ou de synchronisation, il est possible de saisir les notes de plusieurs juges sur un même appareil.</p>
                    <p>Cliques le bouton ci-dessous pour entrer dans ce mode</p>
                    <b-button variant="secondary" @click="openJudgeSelector">Saisie autre juge</b-button>
                  </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import { REF_PENALTY, RST_EVENT, RST_CATEGORY, RST_LEVEL, RST_ROUND, RST_COMPETITION, RST_EVENT_COMPETITOR,  RST_FIELD, RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE, RST_BALLET_NOTE } from "@/LocalDB";
import { mapGetters, mapMutations } from 'vuex';
import compulsoryNoteEditor from "@/components/ScoringGrid/CompulsoryNoteEditor";
import routineNoteEditor from "@/components/ScoringGrid/RoutineNoteEditor";
import balletNoteEditor from "@/components/ScoringGrid/BalletNoteEditor";
import detailedResults from "@/components/ScoringGrid/DetailedResults";
import EventHeader from "./Components/EventHeader";
import compulsoryNoteResult from "@/components/ScoringGrid/CompulsoryNoteResult";
import RoutineNoteResult from '@/components/ScoringGrid/RoutineNoteResult';
import BalletNoteResult from '@/components/ScoringGrid/BalletNoteResult';
import CompetitorEventResult from './Components/CompetitorEventResult';
import { CompetitorsNotesHelper } from "@/services/helpers/competitorsNotesHelper";
import SynchroHelper from "@/services/helpers/synchroHelper";
import { EventExecutionStepEnum } from "@/Constants";
import FormActionButtonsArea from '@/components/FormActionButtonsArea';
import InfoHelper from '@/components/InfoHelper';

export default {
  components: { compulsoryNoteEditor, routineNoteEditor, balletNoteEditor, detailedResults, EventHeader, compulsoryNoteResult, BalletNoteResult, RoutineNoteResult, CompetitorEventResult, FormActionButtonsArea, InfoHelper },

    data(){
        return {
            showNoteEditor:false,
            showJudgeSelector:false,
            currentStepLabels: { label1: null, label2: null, isUsable: false },
            errorAllA: false,

            currentUserStaffMember: null,

            intervalId : null,
            judgesList: []
        };
    },
    computed:{
        ...mapGetters('current', ['currentJudge']),
        ...mapGetters('config', ['cfgDelayBetweenCallsToCheckNotesOfOtherJudges']),
        currentCompetitor(){
          return this.currentEvent.CURRENT_COMPETITOR;
        },
        showWaitForOtherJudge:{
          get(){ return !this.showNoteEditor; },
          set(val){ this.showNoteEditor = !val; }
        },
        judges(){
            var ret = this.currentEvent.JUDGES;
            ret.sort((a,b) => { return (a.shortname < b.shortname) ? -1 : ((a.shortname > b.shortname) ? 1 : 0)});
            return ret;
        },
        isCurrentlyCompulsories(){
          return (this.currentEvent.current_step == EventExecutionStepEnum.Compulsories);
        },
        isCurrentlyRoutine(){
          return (this.currentEvent.current_step == EventExecutionStepEnum.Routine);
        },
        isCurrentlyBallet(){
          return (this.currentEvent.current_step == EventExecutionStepEnum.Ballet);
        },
        compulsoriesPenalties(){
          return REF_PENALTY.query().where(p => p.regulation == this.currentEvent.COMPETITION.regulation).where(p => p.isAvailableForCompulsory == true).get();
        },
        routinePenalties(){
          return REF_PENALTY.query().where(p => p.regulation == this.currentEvent.COMPETITION.regulation).where(p => p.isAvailableForRoutine == true).get();
        },
        balletPenalties(){
          return REF_PENALTY.query().where(p => p.regulation == this.currentEvent.COMPETITION.regulation).where(p => p.isAvailableForBallet == true).get();
        },
        showSummary:{
            get:function(){
                return this.currentEvent.isShowingStepSummary;
            },
            set:function(newValue){
              this.$showRefresher()
              .then(() => {
                  var data = {
                      isShowingStepSummary: newValue
                  };
                  RST_EVENT.update({ where: this.currentEvent.id, data: data });
                  SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, data, "SHOW_SUMMARY");
                  SynchroHelper.TryToSynchronize();
                  this.$hideRefresher();
                });
            }
        },
        isSkippableCompetitor(){
            if(this.currentCompetitor == null)
                return false;
            if(this.currentEvent.EVENT.hasCompulsories){
                if(!this.isCurrentlyCompulsories)
                    return false;
                if(this.currentEvent.current_compulsory_index != 0 || this.showSummary)
                    return false;
                if(RST_COMPULSORY_NOTE.query()
                            .where("competition_id", this.currentCompetition_id)
                            .where("round_number", this.currentRound_number)
                            .where("level", this.currentLevel_code)
                            .where("category", this.currentCategory_code)
                            .where("event", this.currentEvent.event)
                            .where("competitor_id", this.currentCompetitor_id)
                            .where("compulsory_id", this.currentEvent.current_compulsory_id)
                            .where(note => { return note.judge_id != null; })
                            .where(note => { return note.noteEntry != null || note.isAverage; })
                            .exists()
                )
                  return false;
                return this.currentEvent._EFFECTIVE_COMPETITORS
                    .where(ec => ec.isWaiting == true)
                    .where(ec => ec.competitor_id != this.currentCompetitor.id)
                    .exists();
            }
            if(this.currentEvent.EVENT.hasRoutine)
            {
              if(!this.isCurrentlyRoutine)
                return false;

              if(RST_ROUTINE_NOTE.query()
                            .where("competition_id", this.currentCompetition_id)
                            .where("round_number", this.currentRound_number)
                            .where("level", this.currentLevel_code)
                            .where("category", this.currentCategory_code)
                            .where("event", this.currentEvent.event)
                            .where("competitor_id", this.currentCompetitor_id)
                            .where(note => { return note.judge_id != null; })
                            .where(note => { return note.total != null; })
                            .exists())
                return false;
                return this.currentEvent._EFFECTIVE_COMPETITORS
                    .where(ec => ec.isWaiting == true)
                    .where(ec => ec.competitor_id != this.currentCompetitor.id)
                    .exists();
            }
            if(this.currentEvent.EVENT.hasBallet)
            {
              if(!this.isCurrentlyBallet)
                return false;
              if(RST_BALLET_NOTE.query()
                            .where("competition_id", this.currentCompetition_id)
                            .where("round_number", this.currentRound_number)
                            .where("level", this.currentLevel_code)
                            .where("category", this.currentCategory_code)
                            .where("event", this.currentEvent.event)
                            .where("competitor_id", this.currentCompetitor_id)
                            .where(note => { return note.judge_id != null; })
                            .where(note => { return note.total != null; })
                            .exists())
                return false;
                return this.currentEvent._EFFECTIVE_COMPETITORS
                    .where(ec => ec.isWaiting == true)
                    .where(ec => ec.competitor_id != this.currentCompetitor.id)
                    .exists();
            }
            return true;
        }
    },
    methods:{
        ...mapMutations('current', [ 'setCurrentJudge_id' ]),
        async openJudgeSelector(){
          await this.refreshJudgesList();
          this.showJudgeSelector = true;
        },
        async refreshJudgesList(){
            var ret = this.currentEvent.JUDGES;
            var notes = [];
            if(this.isCurrentlyCompulsories)
            {
              notes = RST_COMPULSORY_NOTE.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("round_number", this.currentEvent.round_number)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("event", this.currentEvent.event)
                            .where('competitor_id', this.currentEvent.current_competitor_id)
                            .where("compulsory_id", this.currentEvent.current_compulsory_id)
                            .where(n => n.judge_id != null)
                            .get()
                            .map(n => { return {staff_id: n.judge_id, isCompleted: n.isCompleted};});
            } else if(this.isCurrentlyRoutine) {
              notes = RST_ROUTINE_NOTE.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("round_number", this.currentEvent.round_number)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("event", this.currentEvent.event)
                            .where('competitor_id', this.currentEvent.current_competitor_id)
                            .where(n => n.judge_id != null)
                            .get()
                            .map(n => { return {staff_id: n.judge_id, isCompleted: n.isCompleted};});
            } else if(this.isCurrentlyBallet){
              notes = RST_BALLET_NOTE.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("round_number", this.currentEvent.round_number)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("event", this.currentEvent.event)
                            .where('competitor_id', this.currentEvent.current_competitor_id)
                            .where(n => n.judge_id != null)
                            .get()
                            .map(n => { return {staff_id: n.judge_id, isCompleted: n.isCompleted};});
            }
            ret = ret.map(n => {
                      var jn = notes.find(nn => nn.staff_id == n.staff_id);
                      n.isCompleted = jn.isCompleted;
                      return n;
                    });
            ret.sort((a,b) => { return (a.shortname < b.shortname) ? -1 : ((a.shortname > b.shortname) ? 1 : 0)});
            this.judgesList = ret;
        },
        async backToDashboard(freeField){
          await this.$showLoader();
          if(freeField)
          {
            var field = RST_FIELD.query().where("competition_id", this.currentCompetition_id).where("field_number", this.currentEvent.field_number).first();
            if(field != null){
              var data = { isHot: false};
              RST_FIELD.update({ where: field.id, data: data });
              var operationId = SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_HOT_STATE");
              var result = await SynchroHelper.TryToSynchronize([operationId]);
              this.toastAsyncSaved(result, "Le terrain est maintenant libre");
            }
          }
          this.$router.push({ name: "CompetitionEditOrRun"});
        },

        setEventStateStarted(){
          this.currentEvent.isStarted = true; this.currentEvent.isSync = false; RST_EVENT.insertOrUpdate({ data: this.currentEvent });
          this.currentCategory.isStarted = true; this.currentCategory.isSync = false; RST_CATEGORY.insertOrUpdate({ data: this.currentCategory });
          this.currentLevel.isStarted = true; this.currentLevel.isSync = false; RST_LEVEL.insertOrUpdate({ data: this.currentLevel });
          this.currentRound.isStarted = true; this.currentRound.isSync = false; RST_ROUND.insertOrUpdate({ data: this.currentRound });
          this.currentCompetition.isStarted = true; this.currentCompetition.isSync = false; RST_COMPETITION.insertOrUpdate({ data: this.currentCompetition });
        },
        async checkCompulsoryCompletion(){
          this.$showRefresher();
          await CompetitorsNotesHelper.computeAndSaveCompulsoryTotalAsync(this.currentEvent);
          // La note globale pour cette figure a-t-elle été enregistrée ?
          // En gros, est-ce que tous les juges ont donné leur note pour cette figure ?
          var noteFigure = RST_COMPULSORY_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .where('compulsory_id', this.currentEvent.current_compulsory_id)
            .first();
          if(noteFigure.noteValue != null)
          {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.showWaitForOtherJudge = false;
            this.showSummary = true;
          } else if(this.showJudgeSelector) {
            this.refreshJudgesList();
          }
          this.$hideRefresher();
        },

        /**
         * Enregistre une saisie de note pour une figure de précision
         * @param {*} judgeNoteData 
         */
        async saveCompulsoryNote(judgeNoteData ){
          await this.$showSaver();
          var ok = await CompetitorsNotesHelper.saveCompulsoryNoteAsync(this.currentEvent, this.currentJudge.staff_id, judgeNoteData.entry, judgeNoteData.total, judgeNoteData.penalties, judgeNoteData.penalties.total, judgeNoteData.cartouche);
          this.toastAsyncSaved(ok, "Votre note a bien été enregistrée");

          // Au cas où, on repositionne le juge en cours sur celui qui correspond au user connecté.
          this.setCurrentJudge(this.currentUserStaffMember);

          // La note globale pour cette figure a-t-elle été enregistrée ?
          // En gros, est-ce que tous les juges ont donné leur note pour cette figure ?
          var noteFigure = RST_COMPULSORY_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .where('compulsory_id', this.currentEvent.current_compulsory_id)
            .first();
          if(noteFigure.noteValue == null)
          {
            // Si ce n'est pas le cas, c'est qu'un juge au moins n'a pas encore donné sa note.
            // Peut-être est-ce parce qu'il y a un problème de réseau.
            // On affiche donc une mire d'attente avec possibilité de proposer la saisie de la note pour un autre juge (au cas où)
            // et on initie une vérification autp de l'état dans 2 sec pour s'assurer que les notes manquantes n'ont pas été saisies sur un autre appareil
            this.showWaitForOtherJudge = true;
            if(this.intervalId == null || this.intervalId == 0)
              this.intervalId = setInterval(async function(){ await this.checkCompulsoryCompletion();}.bind(this), this.cfgDelayBetweenCallsToCheckNotesOfOtherJudges);
            this.$hideSaver();
            return;
          }else{
            // si c'est bien le cas, on peut passer à l'étape suivante
            this.showSummary = true;
          }
          
          this.hideSaver();
        },

        async checkRoutineCompletion(){
          this.$showRefresher();
          await CompetitorsNotesHelper.computeAndSaveFinalRoutineNotesAsync(this.currentEvent);
          // La note globale pour cette routine a-t-elle été enregistrée ?
          // En gros, est-ce que tous les juges ont donné leurs notes pour cette routine ?
          var noteRoutine = RST_ROUTINE_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .first();
          if(noteRoutine.note != null)
          {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.showWaitForOtherJudge = false;
            this.showSummary = true;
          } else if(this.showJudgeSelector) {
            this.refreshJudgesList();
          }
          this.$hideRefresher();
        },
        async saveRoutineNote(judgeNoteData){
          await this.showSaver();
          console.log("saveRoutineNote", judgeNoteData);
          var ok = await CompetitorsNotesHelper.saveRoutineNoteAsync(this.currentEvent, this.currentJudge.staff_id, judgeNoteData.execution, judgeNoteData.content, judgeNoteData.total, judgeNoteData.penalties, judgeNoteData.grossTotal, judgeNoteData.grossExecution, judgeNoteData.grossContent, judgeNoteData.penalties.total, judgeNoteData.cartouche);
          console.log('saveRoutineNote', ok);
          this.toastAsyncSaved(ok, "Vos notes ont bien été enregistrées");

          // Au cas où, on repositionne le juge en cours sur celui qui correspond au user connecté.
          this.setCurrentJudge(this.currentUserStaffMember);

          // La note globale pour cette routine a-t-elle été calculée ?
          // En gros, est-ce que tous les juges ont donné leurs notes pour cette routine ?
          var noteRoutine = RST_ROUTINE_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .first();
          if(noteRoutine.note == null)
          {
            // Si ce n'est pas le cas, c'est qu'un juge au moins n'a pas encore donné ses notes.
            // Peut-être est-ce parce qu'il y a un problème de réseau.
            // On affiche donc une mire d'attente avec possibilité de proposer la saisie de la note pour un autre juge (au cas où)
            // et on initie une vérification autp de l'état dans 2 sec pour s'assurer que les notes manquantes n'ont pas été saisies sur un autre appareil
            this.showAwaitForOtherJudge = true;
            this.showNoteEditor = false;
            if(this.intervalId == null || this.intervalId == 0)
              this.intervalId = setInterval(async function(){ await this.checkRoutineCompletion();}.bind(this), this.cfgDelayBetweenCallsToCheckNotesOfOtherJudges);
            this.hideSaver();
            return;
          } else {
            // si c'est bien le cas, on peut passer à l'étape suivante
            this.showSummary = true;
            this.hideSaver();
          }
        },

        async checkBalletCompletion(){
          this.$showRefresher();
          await CompetitorsNotesHelper.computeAndSaveFinalBalletNotesAsync(this.currentEvent);
          // La note globale pour cette routine a-t-elle été enregistrée ?
          // En gros, est-ce que tous les juges ont donné leurs notes pour cette routine ?
          var noteBallet = RST_BALLET_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .first();
          if(noteBallet.note != null)
          {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.showWaitForOtherJudge = false;
            this.showSummary = true;
          } else if(this.showJudgeSelector) {
            this.refreshJudgesList();
          } 
          this.$hideRefresher();
        },
        
        async saveBalletNote(judgeNoteData){
          await this.showSaver();
          console.log("saveBalletNote", judgeNoteData);
          var ok = await CompetitorsNotesHelper.saveBalletNoteAsync(this.currentEvent, this.currentJudge.staff_id, judgeNoteData.choreo, judgeNoteData.execution, judgeNoteData.total, judgeNoteData.penalties, judgeNoteData.grossChoreo, judgeNoteData.grossExecution, judgeNoteData.grossTotal, judgeNoteData.penalties.total, judgeNoteData.cartouche);
          console.log('saveBalletNote', ok);
          this.toastAsyncSaved(ok, "Vos notes ont bien été enregistrées");

          // Au cas où, on repositionne le juge en cours sur celui qui correspond au user connecté.
          this.setCurrentJudge(this.currentUserStaffMember);

          // La note globale pour cette routine a-t-elle été calculée ?
          // En gros, est-ce que tous les juges ont donné leurs notes pour cette routine ?
          var noteBallet = RST_BALLET_NOTE.query()
            .where('competition_id', this.currentEvent.competition_id)
            .where('round_number', this.currentEvent.round_number)
            .where('level', this.currentEvent.level)
            .where('category', this.currentEvent.category)
            .where('event', this.currentEvent.event)
            .where('competitor_id', this.currentEvent.current_competitor_id)
            .where(n => n.judge_id == null)
            .first();
          if(noteBallet.note == null)
          {
            // Si ce n'est pas le cas, c'est qu'un juge au moins n'a pas encore donné ses notes.
            // Peut-être est-ce parce qu'il y a un problème de réseau.
            // On affiche donc une mire d'attente avec possibilité de proposer la saisie de la note pour un autre juge (au cas où)
            // et on initie une vérification autp de l'état dans 2 sec pour s'assurer que les notes manquantes n'ont pas été saisies sur un autre appareil
            this.showAwaitForOtherJudge = true;
            this.showNoteEditor = false;
            if(this.intervalId == null || this.intervalId == 0)
              this.intervalId = setInterval(async function(){ await this.checkBalletCompletion();}.bind(this), this.cfgDelayBetweenCallsToCheckNotesOfOtherJudges);
            this.hideSaver();
            return;
          } else {
            // si c'est bien le cas, on peut passer à l'étape suivante
            this.showSummary = true;
            this.hideSaver();
          }
        },

        modifyJudgeNote(judgeId){
            this.setCurrentJudge_id(judgeId);
            this.showNoteEditor = true;
            this.showSummary = false;
        },

        skipCompetitor(){
            this.nextCompetitor();
        },

        setCurrentJudge(judge){
            this.setCurrentJudge_id(judge.id);
            this.showNoteEditor = true;
        },
        resetCurrentFigureNotes(){
          this.showSummary = false;
          this.$showRefresher()
          .then(() => {
              CompetitorsNotesHelper.razCompetitorCompulsoryNotes(this.currentEvent.competition_id, this.currentEvent.round_number, this.currentEvent.level, this.currentEvent.category, this.currentEvent.CURRENT_COMPETITOR.competitor_id, this.currentEvent.CURRENT_COMPULSORY.Compulsory_id)
              this.errorAllA = false;
              this.showSummary = false;
              this.$hideRefresher();
            });
        },

        setCurrentStepLabels(){
            if(this.currentEvent.isCompleted)
              return "";
            
            switch(this.currentEvent.current_step){
                case EventExecutionStepEnum.Compulsories:
                    this.currentStepLabels = {
                        label1: 'Figure ' + (this.currentEvent.CURRENT_COMPULSORY.order),
                        label2: this.currentEvent.CURRENT_COMPULSORY.code + ' - ' + this.currentEvent.CURRENT_COMPULSORY.name,
                        isUsable: true,
                    }
                    break;
                case EventExecutionStepEnum.Routine:
                    this.currentStepLabels = {
                        label1: '',
                        label2: 'Routine',
                        isUsable: this.currentEvent.EVENT.hasCompulsories || this.currentEvent.EVENT.hasBallet,
                    }
                    break;
                case EventExecutionStepEnum.Ballet:
                    this.currentStepLabels = {
                        label1: '',
                        label2: 'Ballet',
                        isUsable: this.currentEvent.EVENT.hasCompulsories || this.currentEvent.EVENT.hasRoutine,
                    }
                    break;
            }
        },
        nextStep(){
          var compulsoryChange = null;
          var change = null;
            // Puis on passe à la figure suivante (si il en reste) ou à l'étape suivante.
            if(this.isCurrentlyCompulsories)
            {
              var indexOfCurrent = (this.currentEvent.current_compulsory_id == null) ? -1 : this.currentEvent.COMPULSORIES.findIndex(comp => comp.compulsory_id == this.currentEvent.current_compulsory_id);
              var nextIndex = indexOfCurrent + 1;
              if(nextIndex >= 0 && nextIndex < this.currentEvent.COMPULSORIES.length)
              {
                compulsoryChange = { current_compulsory_id: this.currentEvent.COMPULSORIES[nextIndex].compulsory_id };
              }
              else
              {
                compulsoryChange = { current_compulsory_id: null };
                if(this.currentEvent.EVENT.hasRoutine)
                  change = { current_step: EventExecutionStepEnum.Routine };
                else if(this.currentEvent.EVENT.hasBallet)
                  change = { current_step: EventExecutionStepEnum.Ballet };
                else
                  change = { current_step: '' };
              }
            } else if (this.isCurrentlyRoutine) {
              if(this.currentEvent.EVENT.hasBallet)
                change = { current_step: EventExecutionStepEnum.Ballet };
              else
                change = { current_step: ''};
            } else if(this.isCurrentlyBallet) {
                change = { current_step: ''};
            } else {
              if(this.currentEvent.EVENT.hasCompulsories) {
                change = { current_step: EventExecutionStepEnum.Compulsories };
                compulsoryChange = { current_compulsory_id: this.currentEvent.COMPULSORIES[0].compulsory_id};
              } else if(this.currentEvent.EVENT.hasRoutine){
                change = { current_step: EventExecutionStepEnum.Routine};
              } else if(this.currentEvent.EVENT.hasBallet){
                change = { current_step: EventExecutionStepEnum.Ballet};
              }
            }

            if(compulsoryChange != null || change != null)
            {
              this.$showRefresher()
                .then(() => {
                    if(change != null)
                    {
                      console.log(change);
                      RST_EVENT.update({ where: this.currentEvent.id, data: change });
                      SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, change, "CURRENT_STEP");
                    }
                    if(compulsoryChange != null)
                    {
                      console.log(compulsoryChange);
                      RST_EVENT.update({ where: this.currentEvent.id, data: compulsoryChange });
                      SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, compulsoryChange, "CURRENT_COMPULSORY_ID");
                    }
                    SynchroHelper.TryToSynchronize();

                    this.setCurrentStepLabels();
                    this.showSummary = ((this.currentEvent.current_step === null) || (this.currentEvent.current_step === ''));
                });
            }
        },
        async nextCompetitor(){
          console.log('nextCompetitor');
            // Reste-t-il des concurrent à passer pour cet événement
            var someCompetitorRemains = RST_EVENT_COMPETITOR.query()
                    .where("competition_id", this.currentEvent.competition_id)
                    .where("round_number", this.currentEvent.round_number)
                    .where("level", this.currentEvent.level)
                    .where("category", this.currentEvent.category)
                    .where("event", this.currentEvent.event)
                    .where(c => !c.isAbsent && !c.isForfeit && !c.isWithdrawn)
                    .where(c => c.isWaiting)
                    .exists();
            console.log('someCompetitorsRemains exists', someCompetitorRemains);

            if(!someCompetitorRemains){
              await this.$showRefresher();
                var change = {
                  current_competitor_id: null,
                  current_compulsory_id: null,
                  current_step: null,
                  isCompleted: true
                };
                RST_EVENT.update({ where: this.currentEvent.id,  data: change});
                SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, change, "END_EVENT");
                
                this.$hideRefresher();
            } else {
              var currentCompetitorIndex = this.currentEvent._EFFECTIVE_COMPETITORS.orderBy('predicted_order').get().findIndex(comp => comp.competitor_id == this.currentEvent.current_competitor_id);
              console.log('currentCompetitorIndex', currentCompetitorIndex);
              var nextCompetitorIndex = currentCompetitorIndex + 1;
              var nextCompetitor = null;
              while(nextCompetitor == null && nextCompetitorIndex != currentCompetitorIndex)
              {
                if(nextCompetitorIndex + 1 > this.currentEvent.EFFECTIVE_COMPETITORS.length){
                  nextCompetitorIndex = 0;
                  console.log('rule 1=>', nextCompetitorIndex);
                  continue;
                }
                if(!this.currentEvent._EFFECTIVE_COMPETITORS.orderBy('predicted_order').get()[nextCompetitorIndex].isWaiting){
                  nextCompetitorIndex++;
                  console.log('rule 2=>', nextCompetitorIndex);
                  continue;
                }
                nextCompetitor = this.currentEvent._EFFECTIVE_COMPETITORS.orderBy('predicted_order').get()[nextCompetitorIndex];
                
              }
              console.log('nextCompetitor', nextCompetitorIndex, nextCompetitor);

              await this.$showSaver()
            }
            change = (nextCompetitor != null)
                        ? {
                            current_competitor_id: nextCompetitor.competitor_id,
                          }
                        : {
                            current_competitor_id: null,
                            current_compulsory_id: null,
                            current_step: null,
                            isCompleted: true
                        };
            RST_EVENT.update({ where: this.currentEvent.id, data: change});
            SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, change, nextCompetitor != null ? "CURRENT_COMPETITOR_ID" : "END_EVENT");
            
            await SynchroHelper.TryToSynchronize();
            this.$hideSaver();
            this.showJudgeSelector = false;
        },
        nextCompetitorOrEndOfEvent(){
          this.nextCompetitor();
          this.setCurrentStepLabels();
                if(this.currentEvent.isCompleted)
                {
                    console.log('Time to compute multi-rounds result');
                } else {
                  this.nextStep();
                }
        },

        cancelNote(){
            this.setCurrentJudge_id(null);
            this.showNoteEditor = false;
        }
    },
    async mounted(){
        this.setCurrentStepLabels();
        var idx = this.currentEvent.STAFF_MEMBERS.findIndex(sm => sm.PEOPLE.visa.toUpperCase() == this.currentUser.visa.toUpperCase());
        this.currentUserStaffMember = this.currentEvent.STAFF_MEMBERS[idx];
        if(this.currentUserStaffMember.isJudge){
          this.showNoteEditor = true;
          this.setCurrentJudge(this.currentUserStaffMember);
        }
        this.$hideLoader();
    }
}
</script>

<style scoped>
    .summary{ font-size: 0.8rem;}
    .competitor > div { font-size: 0.7rem; }
    .competitor > .name { font-size: 1.2rem; font-weight: bolder; }
    .note > { font-size: 0.7rem; }
    .note > .compulsory-number { font-size: 0.7rem;}
    .note > .name { font-size: 1.2rem; font-weight: bolder; }
    .note-edit-instructions { font-size: 0.75rem;}
    .judgesSelector { display: flex; justify-content:center; align-items: center; align-content: center; flex-wrap: wrap;}
    .judgesSelector > button { margin: 0.4rem; padding: 0.1rem; height: 6rem; width:150px;}
    .judgesSelector > button > .name { font-weight: bolder; }
    .judgesSelector > button > .shadow { font-size: 0.6rem; }

    .cartouche{
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom:5px;

    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
            .optional{ display:none; }
    }

</style>
<style>
    table.note-editor-dialog{
      width: 100%;
    }
    table.note-editor-dialog tr {
      min-height: 2rem;
    }
    table.note-editor-dialog tr.even{ background-color: white; }
    table.note-editor-dialog tr.odd{ background-color: whitesmoke; }
    table.note-editor-dialog tr { vertical-align: middle;}
    table.note-editor-dialog td { padding-left: 10px; padding-right: 5px; vertical-align: middle; }
    table.note-editor-dialog td.setter { text-align: center;  }

    .note { font-size: 0.6rem;  }
    .competitor{ font-size: 0.7rem;}
    .note > .name { font-size: 1.1rem; font-weight: bolder; }
    .noteEntry { font-size: 2.5rem; width:6rem; height:3rem; text-align:center; }
    .noteCheck { font-size: 2.5rem; text-align: center; padding: 2px 4px; margin: 2px 15px; border:solid 1px black; width:6rem; font-weight:bolder; }
    .noteCheck > div { font-size: 1rem; text-align:center; width: 100%; color: var(--danger); font-weight:bolder; }
    .endNoteEntry { height: 2rem; margin:1rem; width:110px; }
    .endNoteEntry{ min-height: 3.5rem; font-size: 1.3rem;}

    .noteEntryContainer{
        display: flex;
        justify-content: center;
        align-content:center;
        align-items: center;
    }
    fieldset.noteEntry{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 3.7rem;
        width: 6rem;
        padding: 1px;
        font-size:0.8rem;
        margin:5px;
        border-style:none;
    }
</style>
