<template>
    <div>
        <span
            class="noteEntry"
            v-if="disabled || averageEntry"
        >
            {{ averageEntry ? "A" : noteEntry }}
        </span>
        <div class="noteEntryError">{{ noteError }}</div>
        <div class="noteEntryContainer">
            <b-form-input
                v-model="noteEntry"
                type="number"
                class="noteEntry"
                v-if="!disabled && !averageEntry"
                :disabled="averageEntry"
                @keypress="note_keypress"
                @input="note_input"
                size="lg"
                ref="note"
            />
            <b-form-checkbox
                switch
                size="lg"
                v-model="averageEntry"
                v-if="!disabled"
            >
                Average
            </b-form-checkbox>
            <div style="display:flex; color:red; font-style:italic; font-size:larger; justify-content: center;">{{ penaltiesValue }}</div>
            <b-button variant="warning" size="xs" @click="showPenalties=true">Détails & pénalités</b-button>

        <b-container style="display:flex; justify-content: center;">
        <b-button
            class="endNoteEntry cancel"
            variant="secondary"
            size="sm"
            @click="cancel_click"
        >
            {{ disabled ? '&lt;&lt; Retour' : '&lt;&lt; Annuler' }}
        </b-button>
        <b-button
            v-if='!disabled'
            class="endNoteEntry validate"
            :variant="noteIsValid || penaltiesSet.zero ? 'primary' : 'danger'"
            size="sm"
            :disabled="!noteIsValid && !penaltiesSet.zero"
            @click="validate_click"
        >
            Valider &gt;&gt;
        </b-button>
        </b-container>
        </div>
        <b-modal
            title="Détails & Pénalités"
            centered
            static
            lazy
            size="xs"
            v-model="showPenalties"
            scrollable
            class="details-and-penalties compulsory"
        >
            <template #modal-footer="{cancel}">
                <b-button size="xs" variant="primary" @click="cancel(); showPenalties=false;">Fermer</b-button>
            </template>
            
            <compulsory-note-details-editor
              v-model="cartouche"
              :compulsoryPanelVisible="compulsoryVisible"
              :compulsoryIncludeModel="compulsoryIncludeModel"
              :compulsoryIncludeText="compulsoryIncludeText"
              :commentsPanelVisible="commentsVisible"
            >
              <template #between-compulsory-and-comments>
                <collapse-panel title="Pénalités" :visible="penaltiesVisible" @expand="penaltiesVisible=true" @collapse="penaltiesVisible=false">
                    <compulsory-penalties-editor
                        v-model="penaltiesSet"
                        @change="penalties_change"
                    />
                </collapse-panel>
              </template>
            </compulsory-note-details-editor>

            <collapse-panel title="Mes préférences (par défaut)" :visible="false">
              <checkbox-form-field caption='Volet "Figure" déplié' switch v-model="compulsoryVisible" />
              <checkbox-form-field caption='Volet "Pénalités" déplié' switch v-model="penaltiesVisible" />
              <checkbox-form-field caption='Volet "Notes/Commentaires" déplié' switch v-model="commentsVisible" />
            </collapse-panel>

        </b-modal>

        <b-modal
            title="Confirmation de saisie"
            centered
            :static="true"
            :lazy="true"
            size="xs"
            v-if="cfgConfirmNoteEntryByPopup"
            v-model="showConfirmationModal"
            ok-variant="success"
            ok-title="Oui"
            cancel-variant="warning"
            cancel-title="non, je veux corriger ma note"
            button-size="xs"
            class="confirm-notes-entry compulsory"
            >
            <template #modal-footer="{cancel}">
                <b-button size="xs" variant="warning" @click="cancel()">Non, je veux corriger ma note</b-button>
                <b-button size="lg" variant="success" @click="validate_click">Oui</b-button>
            </template>
            <div v-if="averageEntry || noteEntry != null">Vous avez saisi la note suivante
                <div class="noteEntryContainer">
                    <div class="noteCheck">{{ averageEntry ? "A" : noteEntry }}</div>
                </div>
            </div>
            <div v-else>
                Vous n'avez pas entré de note
            </div>
            <div v-if="penaltiesValue != ''">{{ confirmDialog_penaltiesText }} : 
                <ul v-if="penaltiesValue.split(',').length > 1" style="display:flex; flex-direction: column; justify-content:center; font-weight:bolder; font-size: larger; color: var(--ffvlDanger)">
                    <li v-for="penalty in penaltiesValue.split(',')" :key='penalty'>{{ penalty }}</li>
                </ul>
                <div v-else style="display:flex; justify-content:center; font-weight: bolder; font-size: larger; color: var(--ffvlDanger)">
                    {{ penaltiesValue }}
                </div>
            </div>

            <div>{{ confirDialog_endQuestion }}</div>
            <i class="warning">ATTENTION ! {{ confirmDialog_warningText }}</i>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CompulsoryNoteDetailsEditor from './CompulsoryNoteDetailsEditor.vue';
import CompulsoryPenaltiesEditor from './CompulsoryPenaltiesEditor.vue';
import CollapsePanel from '../Panels/CollapsePanel.vue';
import CheckboxFormField from '../Panels/CheckboxFormField.vue';

export default {
  components: { CompulsoryPenaltiesEditor, CompulsoryNoteDetailsEditor, CollapsePanel, CheckboxFormField },
    props: {
        value: { type: String, default: null },
        minValue: { type: Number, default: 0 },
        maxValue: { type: Number, default: 100 },
        disabled: { type: Boolean, default: false },
        possiblePenalties: { type: Array, default: null },
    },
    data(){
        return {
            previousEntry: (this.value == "A" || this.value == "a") ? "" : this.value,
            noteEntry: (this.value == "A" || this.value == "a") ? null : this.value ,
            averageEntry: (this.value == "A" || this.value == "a"),
            //noteIsValid: false,
            noteError: '',
            showConfirmationModal: false,
            penaltiesSet: { version: 1, total: 0, count: 0},
            cartouche: {
              version: 1,
            },
            showPenalties: false,
        };
    },
    computed:{
        ...mapGetters('config', ['cfgCompulsoryNoteEditor_compulsoryPaneVisible', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'cfgCompulsoryNoteEditor_penaltiesPaneVisible', 'cfgCompulsoryNoteEditor_commentsPaneVisible']),

        compulsoryVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneVisible (newVal);}
        },
        compulsoryIncludeModel:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel (newVal);}
        },
        compulsoryIncludeText:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeText; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText (newVal);}
        },
        penaltiesVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_penaltiesPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_penaltiesPaneVisible (newVal);}
        },
        commentsVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_commentsPaneVisible (newVal);}
        },

        note:{
            get:() => this.averageEntry ? "A" : this.noteEntry,
            set:(newValue) =>{
                if(newValue == "A" || newValue == "a")
                {
                    this.averageEntry = true;
                    this.noteEntry = null;
                    this.noteError = '';
                } else {
                    const regExp = new RegExp('^([Aa]|[1-9][0-9]|[0-9]|100)$');
                    if(regExp.test(newValue))
                    {
                        this.noteEntry = newValue;
                        this.averageEntry = false;
                        this.noteError = '';
                    }
                    else{
                        this.noteEntry = null;
                        this.averageEntry = false;
                        this.noteError = "Note non valide.";
                    }
                }
            }
        },
        noteIsValid(){
            return (this.penaltiesSet.zero || this.averageEntry || (this.noteEntry != null && this.noteEntry != "")) && (this.noteError == null || this.noteError == "");
        },
        confirmDialog_penaltiesText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return '';
          var prefix = (this.averageEntry || this.noteEntry != null) ? "Et" : "Mais";
          if(this.penaltiesSet.count == 1)
            return prefix + " la pénalité suivante";
          return prefix + " les pénalités suivantes";
        },
        confirDialog_endQuestion(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Confirmez-vous cette note ?";
          else if(this.penaltiesSet.count == 1)
            return "Confirmez-vous cette note et cette pénalité ?";
          return "Confirmez-vous cette note et ces pénalités ?";
        },
        confirmDialog_warningText(){
          if(this.penaltiesSet == null || this.possiblePenalties.count == 0)
            return "Une fois confirmée, votre note ne pourra plus être modifiée (ni aucune pénalité ajoutée).";
          else if(this.penaltiesSet.count == 1)
            return "Une fois confirmées, votre note et la pénalité posée ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
          return "Une fois confirmées, votre note et les pénalités posées ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
        },
        penaltiesValue(){
            if(this.penaltiesSet.count == 0)
                return "";
            var ret = [];
            if(this.penaltiesSet.absenceDebutOuFin) ret.push('Absence de début ou de fin');
            if(this.penaltiesSet.depassementTpsPrepa) ret.push('Dépassement du temps de préparation');
            if(this.penaltiesSet.mauvaiseFigure) ret.push('Présentation de la mauvaise figure');
            if(this.penaltiesSet.crash) ret.push('Crash');
            if(this.penaltiesSet.contacts) ret.push('Contact(s) accidentel(s)');

            if(this.penaltiesSet.sortieTerrain) ret.push('Vol/déplacement au-dessus des limites du terrain');

            return "".concat(ret);
        }
    },
    methods:{
        ...mapMutations('config', ['setCfgCompulsoryNoteEditor_compulsoryPaneVisible', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'setCfgCompulsoryNoteEditor_penaltiesPaneVisible', 'setCfgCompulsoryNoteEditor_commentsPaneVisible', ]),
        note_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter')
              {
                this.validate_click();
              } else {
                e.preventDefault();
                this.noteError = "La note doit être un nombre entier (sans virgule) compris entre " + this.minValue + " et " + this.maxValue + ".";
              }
            }
        },
        note_input(){
            this.noteError = '';
            if(!this.averageEntry && this.noteEntry != null && this.noteEntry != "")
            {
                var numEntry = parseInt(this.noteEntry);
                if(numEntry < this.minValue || numEntry > this.maxValue){
                    this.noteError = "La note doit être comprise entre " + this.minValue + " et " + this.maxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntry = this.previousEntry;
                    });
                }
                else
                {
                    this.previousEntry = this.noteEntry;
                }
            }
       },
       penalties_change(){
            var ret = 0;
            var cnt = 0;
            var zero = false;
            if(this.penaltiesSet.absenceDebutOuFin) { zero=true; cnt++; }
            if(this.penaltiesSet.depassementTpsPrepa) {ret += 5; cnt++; }
            if(this.penaltiesSet.mauvaiseFigure) { zero=true; cnt++; }
            if(this.penaltiesSet.crash) { zero=true; cnt++; }
            if(this.penaltiesSet.contacts) { ret+= 10; cnt++; }

            if(this.penaltiesSet.sortieTerrain) { ret+=15; cnt++; }

            this.penaltiesSet.total = ret;
            this.penaltiesSet.count = cnt;
            this.penaltiesSet.zero = zero;
       },
       validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            var total = this.averageEntry ? "A" : this.noteEntry;
            if(!this.averageEntry && this.penaltiesSet.count > 0)
            {
                total = this.penaltiesSet.zero ? 0 : total - this.penaltiesSet.total;
                if(total < 0) total = 0;
            } 
            this.$emit('change', { entry: this.averageEntry ? "A" : parseInt(this.noteEntry), total: total, penalties: this.penaltiesSet, cartouche: this.cartouche});
            this.showConfirmationModal = false;
        },
        cancel_click(){
            this.$emit('cancel');
        }
        ,
        focus()
        {
            this.$refs.note.focus();
        }
    },
    mounted(){
        this.showConfirmationModal = false;
        this.focus();
    }
}
</script>

<style scoped>
    .noteEntryContainer{
        flex-direction: column;
    }
    input[type="checkbox"]{
        width:20px !important;
        height:20px !important;
    }
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
</style>

<style>
    .note-editor-dialog > tr {
        min-height:35px;
        height: 35px;
        vertical-align: center;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .confirm-notes-entry .modal-dialog.modal-xs{
            transform: scale(0.95, 0.95) translate(-10px, -200px)
        }

        .confirm-notes-entry.compulsory .modal-dialog.modal-xs{
            transform: scale(0.95, 0.95) translate(-10px, -170px)
        }

        .details-and-penalties .modal-dialog.modal-xs > .modal-content{
            transform: scale(0.95, 0.95) translate(-10px, -50px);
        }

        .details-and-penalties.compulsory .modal-dialog.modal-xs > .modal-content{
            transform: scale(0.95,0.95) translate(-10px, -100px);
        }
    }
</style>
