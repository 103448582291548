<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel title="Figure" :visible="compulsoryPanelVisible" @expand="compulsoryPanelVisible=true" @collapse="compulsoryPanelVisible=false">
            <table class="note-editor-dialog">
                <tr class="even">
                    <td @click="edtCartouche.isExecCorrect=null">Exécution correcte ?</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.isExecCorrect" :options="['Oui', 'Non', 'En partie']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.isWellSized=null">Bien proportionnée ?</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.isWellSized" :options="['Oui', 'Non', 'En partie']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
            </table>
            <div>
                <b-checkbox switch v-model="compulsoryIncludeModel" inline>Diagramme</b-checkbox>
                <b-checkbox switch v-model="compulsoryIncludeText" inline>Détails</b-checkbox>
            </div>
            <compulsory-image :includeModel="compulsoryIncludeModel" :includeText="compulsoryIncludeText" v-model="currentEvent.CURRENT_COMPULSORY.code" />
        </collapse-panel>
        <slot name="between-compulsory-and-comments"></slot>
        <collapse-panel title="Notes" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import CompulsoryImage from '../CompulsoryImage.vue';

export default {
    components:{ CollapsePanel, CompulsoryImage },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
        /*compulsoryPanelVisible: { type:Boolean, default: true},
        compulsoryIncludeModel: { type:Boolean, default: true},
        compulsoryIncludeText: { type:Boolean, default: true},
        commentsPanelVisible: {type: Boolean, default: true},*/
    },
    data(){
        return {
            edtCartouche: this.value,
        };
    },
    computed:{
        ...mapGetters('config', ['cfgCompulsoryNoteEditor_compulsoryPaneVisible', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'cfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'cfgCompulsoryNoteEditor_commentsPaneVisible']),

        compulsoryPanelVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneVisible (newVal);}
        },
        compulsoryIncludeModel:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel (newVal);}
        },
        compulsoryIncludeText:{
            get: function() { return this.cfgCompulsoryNoteEditor_compulsoryPaneIncludeText; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText (newVal);}
        },
        commentsPanelVisible:{
            get: function() { return this.cfgCompulsoryNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgCompulsoryNoteEditor_commentsPaneVisible (newVal);}
        },
    },
    methods:{
        ...mapMutations('config', ['setCfgCompulsoryNoteEditor_compulsoryPaneVisible', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel', 'setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText', 'setCfgCompulsoryNoteEditor_commentsPaneVisible', ]),
        emit_change(){
            this.$emit("change");
        }
    },
}
</script>

<style scoped>
/*
    td{ padding-left: 10px; padding-right: 5px;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
*/
</style>
