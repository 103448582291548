<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel title="Exécution" :visible="executionPanelVisible" @expand="executionPanelVisible=true" @collapse="executionPanelVisible=false">
            <table class="note-editor-dialog">
                <tr class="even">
                    <td @click="edtCartouche.controle=null">contrôle</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.controle" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.utilisationFenetre=null">utilisation de la fenêtre</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.utilisationFenetre" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.difficulteTechnique=null">difficulté technique</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.difficulteTechnique" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.rythmeExec=null">rythme</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.rythmeExec" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.timing=null">timing</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.timing" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.espacement=null">espacement</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.espacement" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
             </table>
        </collapse-panel>
        <slot name="between-execution-and-choregraphy"></slot>
        <collapse-panel title="Chorégraphie" :visible="choregraphyPanelVisible" @expand="choregraphyPanelVisible=true" @collapse="choregraphyPanelVisible=false;">
            <table class="note-editor-dialog">
                <tr class="even">
                    <td @click="edtCartouche.continuite=null">continuité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.continuite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.creativite=null">créativité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.creativite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.complexite=null">complexité</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.complexite" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="odd">
                    <td @click="edtCartouche.interpretation=null">interprétation</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.interpretation" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
                <tr class="even">
                    <td @click="edtCartouche.rythmeChoreo=null">rythme</td>
                    <td class="note-detail"><b-radio-group v-model="edtCartouche.rythmeChoreo" :options="['faible', 'moyen', 'bon']" buttons button-variant="outline-secondary" :disabled="readonly" @change="emit_change"></b-radio-group></td>
                </tr>
            </table>
        </collapse-panel>
        <slot name="between-choregraphy-and-comments"></slot>
         <collapse-panel title="Notes" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

export default {
    components:{ CollapsePanel },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
        //executionPanelVisible: { type:Boolean, default: true},
        //choregraphyPanelVisible: { type:Boolean, default: true},
        //commentsPanelVisible: {type: Boolean, default: true},
    },
    data(){
        return {
            edtCartouche: this.value,
        };
    },
    computed:{
        ...mapGetters('config', [ 'cfgBalletNoteEditor_executionPaneVisible', 'cfgBalletNoteEditor_choregraphyPaneVisible', 'cfgBalletNoteEditor_commentsPaneVisible']),

        executionPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_executionPaneVisible(newVal); }
        },
        choregraphyPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_choregraphyPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_choregraphyPaneVisible(newVal); }
        },
        commentsPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_commentsPaneVisible(newVal); }
        },
    },
    methods:{
        ...mapMutations('config', [ 'setCfgBalletNoteEditor_executionPaneVisible', 'setCfgBalletNoteEditor_choregraphyPaneVisible', 'setCfgBalletNoteEditor_commentsPaneVisible']),
     
        emit_change(){
            this.$emit('change');
        },
    },
    /*watch:{
        value: function(newValue){
            this.edtCartouche = newValue;
        }
    }*/
}
</script>

<style scoped>
/*
    td{ padding-left: 10px; padding-right: 5px;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
*/
</style>
