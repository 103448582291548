<template>
    <div>
        <div class="renderer">
            <slot name="Start"></slot>
        </div>
        <div class="renderer">
            <div class="global details">
                <div class="global execution">
                    <div>Execution</div>
                    <div class="note">{{ arrondir(noteGlobale.execution) }}</div>
                </div>
                <div class="global content">
                    <div>Contenu</div>
                    <div class="note">{{ arrondir(noteGlobale.content) }}</div>
                </div>
            </div>
            <div class="global">{{ arrondir(noteGlobale.note) }}</div>
        </div>
        <div class="renderer">
            <slot name="Middle">
                <b-button class="nextStep" variant="primary" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
        <div class="renderer" v-if="cfgVerboseNoteSummary">
            <div v-for="judgeNote in notesPerJudges" :key="judgeNote.judge_id" style="display:none">
                <div class="judgeNote">
                    <div class="judgeName">{{ judgeNote.JUDGE.PEOPLE.shortname}}</div>
                    <div class="note">{{ arrondir(judgeNote.note) }}</div>
                    <div class="execution">Exec. : {{ judgeNote.execution }}</div>
                    <div class="content">Contenu : {{ judgeNote.content}}</div>
                    <div>
                        <b-button
                            v-if="false"
                            @click="modifyJudgeNote(judgeNote.judge_id)"
                            size="sm"
                        >
                            Modifier
                        </b-button>
                    </div>
                </div>
            </div>
            <b-table small hover :items="notesPerJudges" :fields="verbodeModeTableColumns" class="verboseNotes" responsive thead-class="headerClass">
                <template #cell(judge)="data">
                  <span class="judge" :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.JUDGE.PEOPLE.shortname }}</span>
                </template>
                <template #cell(note)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}"><b>{{ arrondir(data.item.note ) }}</b></span>
                </template>
                <template #cell(grossExecution)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.grossExecution }}</span>
                </template>                
                <template #cell(grossContent)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.grossContent }}</span>
                </template>         
                <template #cell(penalty)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ data.item.penalty || '0'}}</span>
                </template>
                <template #cell(grossTotal)="data">
                    <span :class="{'shadow-note' : data.item.isShadowNote}">{{ arrondir(data.item.grossTotal) }}</span>
                </template>
            </b-table>        </div>
        <div class="renderer">
            <slot name="End"></slot>
        </div>
    </div>
</template>

<script>
import { RST_ROUTINE_NOTE } from "@/LocalDB";

export default {
    props: {
    },
    data(){
        return {
            noteGlobale: '',
            notesPerJudges: [],
            verbodeModeTableColumns: [
                { key: "judge", sortable: false, label:"Juge" },
                { key: "note", sortable:true, label:"Total" },
                { key: "grossExecution", sortable:true, label:"Exec."},
                { key: "grossContent", sortable:true, label:"Cont."},
                { key: "penalty", sortable:true, label:"Pénal."},
                { key: "grossTotal", sortable:true, label:"Tot. brut"}
            ]
        };
    },
    computed:{
    },
    methods:{
        refresh(){
            this.noteGlobale = RST_ROUTINE_NOTE.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where('event', this.currentEvent.event)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .where(n => n.judge_id == null)
                                .first();
//            this.noteGlobale = routineNotes.filter(w => w.judge_id === null)[0];
            if(this.cfgVerboseNoteSummary)
                this.notesPerJudges = RST_ROUTINE_NOTE.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where('event', this.currentEvent.event)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .where(n => n.judge_id != null)
                                .get();
        },
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },
        nextStep(){
            this.$emit("nextStep");
        },
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .global.details{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .global.execution, .global.content{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 45px;
        font-size:0.25em;
        border:none;
        font-weight: lighter;
    }
    .global.execution > .note, .global.content > .note{
        font-size:2.5em;
        font-weight: lighter;
    }
    .global.content {
        border-top:solid 1px black;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        /*flex-wrap: wrap;*/
        font-size:0.8em; border: solid 1px darkgray; color:darkgray; width:100px; height:80px; margin: 20px 10px;
        padding: 2px 0px;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2.5em;
            /*border:solid 4px darkgreen;*/
            /*background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .modifyJudgeNote{
        font-size:0.7em;
        margin: 2px;
    }

</style>
