<template>
    <div v-if="edtPenalties.version==1">
        <table class="note-editor-dialog compulsory penalties">
            <tr class="even"><td>dépassement du temps de préparation</td><td class="setter"><b-checkbox v-model="edtPenalties.depassementTpsPrepa" switch size="lg" :disabled="readonly" @change="emit_change" /></td>
            <!--<td rowspan="8" style="font-size: 1.1rem; color:red; font-weight:bolder; text-align:center; vertical-align: top; min-width:40px; max-width:40px;">
                <div style="border:solid 1px red;">{{ totalPenalties }}</div>
            </td>-->
            </tr>
            <tr class="odd"><td>absence de début ou de fin</td><td class="setter"><b-checkbox v-model="edtPenalties.absenceDebutOuFin" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>

            <tr class="even"><td>présentation mauvaise figure</td><td class="setter"><b-checkbox v-model="edtPenalties.mauvaiseFigure" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="odd"><td>contact(s) accidentels</td><td class="setter"><b-checkbox v-model="edtPenalties.contacts" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="even"><td>crash <info-helper>Avec recours au ramasseur à la demande du compétiteur.</info-helper></td><td class="setter"><b-checkbox v-model="edtPenalties.crash" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="odd"><td>Voler / se déplacer au dessus des limites (DT)<info-helper>Pénalité sur décision du Directeur de Terrain (à appliquer uniquement en cas de demande de sa part).</info-helper></td><td class="setter"><b-checkbox v-model="edtPenalties.sortieTerrain" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>
        </table>
    </div>
</template>

<script>
import InfoHelper from '@/components/InfoHelper.vue'

export default{
  components: { InfoHelper },
    props:{
        value:{ type:Object, required:true},
        readonly:{ type:Boolean, default: false},
    },
    data(){
        return {
            edtPenalties: this.value,
        }
    },
    methods:{
        emit_change(){
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
    td{ padding-left: 10px; padding-right: 5px;}
    td.setter{ text-align: center;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
</style>