<template>
    <div>
        <div class="noteEntryError">{{ noteError }}</div>
        <div class="noteEntryContainer">
            <b-form-group
                class="noteEntry"
                label="Exécution"
            >
                <b-form-input
                    class="noteEntry"
                    v-model="noteEntryE"
                    type="number"
                    @keypress="noteE_keypress"
                    @input="noteE_input"
                    ref="executionNote" />
            </b-form-group>
            <b-form-group
                class="noteEntry"
                label="Contenu"
            >
                <b-form-input
                    class="noteEntry"
                    v-model="noteEntryC"
                    type="number"
                    @keypress="noteC_keypress"
                    @input="noteC_input"
                    ref="contentNote" />
            </b-form-group>
        </div>
        <div style="display:flex; color:red; font-style:italic; font-size:larger; justify-content: center;">{{ penaltiesValue }}</div>
        <div style="display:flex; justify-content: center; margin-top:5px;">
            <b-button variant="warning" size="xs" @click="showPenalties=true">Détails & pénalités</b-button>
        </div>
        <div style="display:flex; justify-content: center; ">
          <b-button
              class="endNoteEntry cancel"
              size="sm"
              variant="secondary"
              @click="cancel_click"
          >
              {{ disabled ? '&lt;&lt; Retour' : '&lt;&lt; Annuler' }}
          </b-button>
          <b-button
              v-if='!disabled'
              class="endNoteEntry validate"
              size="sm"
              :variant="noteIsValid || penaltiesSet.zero ? 'primary' : 'danger'"
              :disabled="!noteIsValid && !penaltiesSet.zero"
              @click="validate_click"
          >
              Valider &gt;&gt;
          </b-button>
      </div>

        <b-modal
            title="Détails & Pénalités"
            centered
            static
            lazy
            size="xs"
            v-model="showPenalties"
            :scrollable="true"
            class="details-and-penalties"
        >
            <template #modal-footer="{cancel}">
                <b-button size="xs" variant="primary" @click="cancel(); showPenalties=false;">Fermer</b-button>
            </template>
            

            <routine-note-details-editor
              v-model="cartouche"
              @change="cartouche_change"
              :executionPanelVisible="executionVisible"
              :contentPanelVisible="contentVisible"
              :commentsPanelVisible="commentsVisible"
            >
              <template #between-content-and-comments>
                <collapse-panel title="Pénalités" :visible="penaltiesVisible" @expand="penaltiesVisible=true" @collapse="penaltiesVisible=false">
                  <Routine-penalties-editor
                      v-model="penaltiesSet"
                      @change="penalties_change"
                  />
                </collapse-panel>
              </template>
            </routine-note-details-editor>

            <collapse-panel title="Mes préférences (par défaut)" :visible="false">
              <checkbox-form-field caption='Volet "Exécution" déplié' switch v-model="executionVisible" />
              <checkbox-form-field caption='Volet "Contenu" déplié' switch v-model="contentVisible" />
              <checkbox-form-field caption='Volet "Pénalités" déplié' switch v-model="penaltiesVisible" />
              <checkbox-form-field caption='Volet "Notes/Commentaires" déplié' switch v-model="commentsVisible" />
            </collapse-panel>
        </b-modal>

        <b-modal
            title="Confirmation de saisie"
            centered
            :static="true"
            :lazy="true"
            size="sm"
            v-if="cfgConfirmNoteEntryByPopup"
            v-model="showConfirmationModal"
            ok-variant="success"
            ok-title="Oui"
            cancel-variant="warning"
            cancel-title="non, je veux corriger mes notes"
            button-size="lg"
            class="confirm-notes-entry"
            >
            <template #modal-footer="{cancel}">
                <b-button size="sm" variant="warning" @click="cancel()">Non, je veux corriger une note</b-button>
                <b-button size="lg" variant="success" @click="validate_click">Oui</b-button>
            </template>
            Vous avez saisi les notes suivantes
            <div class="noteEntryContainer">
                <div class="noteCheck"><div>Execution</div>{{ noteEntryE }}</div>
                <div class="noteCheck"><div>Contenu</div>{{ noteEntryC }}</div>
            </div>
            <div v-if="penaltiesValue != ''">{{ confirmDialog_penaltiesText }} : 
                <ul v-if="penaltiesValue.split(',').length > 1" style="display:flex; flex-direction: column; justify-content:center; font-weight:bolder; font-size: larger; color: var(--ffvlDanger)">
                    <li v-for="penalty in penaltiesValue.split(',')" :key='penalty'>{{ penalty }}</li>
                </ul>
                <div v-else style="display:flex; justify-content:center; font-weight: bolder; font-size: larger; color: var(--ffvlDanger)">
                    {{ penaltiesValue }}
                </div>
            </div>
            <div>{{ confirmDialog_endQuestion }}</div>
            <i class="warning">ATTENTION ! {{ confirmDialog_warningText }}</i>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import RoutineNoteDetailsEditor from './RoutineNoteDetailsEditor.vue';
import RoutinePenaltiesEditor from './RoutinePenaltiesEditor.vue';
import CheckboxFormField from '../Panels/CheckboxFormField.vue';

export default {
  components: { CollapsePanel, RoutinePenaltiesEditor, RoutineNoteDetailsEditor, CheckboxFormField },
    props : {
        disabled:{type:Boolean, default:false,},
        executionMinValue: { type: Number, default: 0 },
        executionMaxValue: { type: Number, default: 100 },
        executionPound : { type: Number, default: 6},
        contentMinValue: { type: Number, default: 0 },
        contentMaxValue: { type: Number, default: 100 },
        contentPound: {type: Number, default: 4},
        execution: { type: Number, default: null},
        content: { type: Number, default: null},
    },
    data(){
        return {
            previousEntryE: this.execution,
            previousEntryC: this.content,
            noteEntryE: this.execution,
            noteEntryC: this.content,
            noteError: '',
            showConfirmationModal: false,
            penaltiesSet: { version: 1, contacts: 0, total: 0, count: 0},
            showPenalties: false,
            cartouche: {
              version: 1,
            },
        };
    },
    computed:{
      ...mapGetters('config', [ 'cfgRoutineNoteEditor_executionPaneVisible', 'cfgRoutineNoteEditor_contentPaneVisible', 'cfgRoutineNoteEditor_penaltiesPaneVisible', 'cfgRoutineNoteEditor_commentsPaneVisible']),

      executionVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_executionPaneVisible(newVal); }
        },
        contentVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_contentPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_contentPaneVisible(newVal); }
        },
        penaltiesVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_penaltiesPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_penaltiesPaneVisible(newVal); }
        },
        commentsVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_commentsPaneVisible(newVal); }
        },
      noteIsValid(){
            return this.penaltiesSet.zero || (( this.noteEntryE != null && this.noteEntryE != "") && (this.noteEntryC != null && this.noteEntryC != "") && (this.noteError == null || this.noteError == ""));
        },
        confirmDialog_penaltiesText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return '';
          if(this.penaltiesSet.count == 1)
            return "Et la pénalité suivante";
          return "Et les pénalités suivantes";
        },
        confirmDialog_endQuestion(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Confirmez-vous ces notes ?";
          else if(this.penaltiesSet.count == 1)
            return "Confirmez-vous ces notes et cette pénalité ?";
          return "Confirmez-vous ces notes et ces pénalités ?";
        },
        confirmDialog_warningText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Une fois confirmées, vos notes ne pourront plus être modifiées (ni aucune pénalité ajoutée).";
          else if(this.penaltiesSet.count == 1)
            return "Une fois confirmées, vos notes et la pénalité posée ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
          return "Une fois confirmées, vos notes et les pénalités posées ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
        },
        penaltiesValue(){
            if(this.penaltiesSet.count == 0)
                return "";
            var ret = [];
            if(this.penaltiesSet.absenceDebut) ret.push('Absence de début');
            if(this.penaltiesSet.absenceFin) ret.push('Absence de fin');
            if(this.penaltiesSet.depassementTpsPrepa) ret.push('Dépassement du temps de préparation');
            if(this.penaltiesSet.mauvaiseFigure) ret.push('Présentation de la mauvaise figure');
            if(this.penaltiesSet.crash) ret.push('Crash');
            if(this.penaltiesSet.contacts) ret.push(this.penaltiesSet.contacts > 1 ? this.penaltiesSet.contacts + ' contacts accidentels' : '1 contact accidentel');

            if(this.penaltiesSet.sortieTerrain) ret.push('Vol/déplacement au-dessus des limites du terrain');

            return "".concat(ret);
        }
    },
    methods:{
      ...mapMutations('config', [ 'setCfgRoutineNoteEditor_executionPaneVisible', 'setCfgRoutineNoteEditor_contentPaneVisible', 'setCfgRoutineNoteEditor_penaltiesPaneVisible', 'setCfgRoutineNoteEditor_commentsPaneVisible']),
        noteE_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter'){
                if(this.noteEntryC == null || this.noteEntryC == ''){
                  this.$refs.noteEntryC.focus();
                }else{
                  this.validate_click();
                }
              } else {
                e.preventDefault();
                this.noteError = "La note d'exécution doit être un nombre entier (sans virgule) compris entre " + this.executionMinValue + " et " + this.executionMaxValue + ".";
              }
            }
        },
        noteC_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter'){
                if(this.noteEntryE == null || this.noteEntryE == ''){
                  this.$refs.noteEntryE.focus();
                }else{
                  this.validate_click();
                }
              } else {
                e.preventDefault();
                this.noteError = "La note de contenu doit être un nombre entier (sans virgule) compris entre " + this.contentMinValue + " et " + this.contentMaxValue + ".";
              }
            }
        },
        noteE_input(){
            this.noteError = '';
            if(this.noteEntryE != null && this.noteEntryE != "")
            {
                var numEntry = parseInt(this.noteEntryE);
                if(numEntry < this.executionMinValue || numEntry > this.executionMaxValue){
                    this.noteError = "La note d'exécution doit être comprise entre " + this.executionMinValue + " et " + this.executionMaxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntryE = this.previousEntryE;
                    });
                }
                else
                {
                    this.previousEntryE = this.noteEntryE;
                }
            }
        },
        noteC_input(){
            this.noteError = '';
            if(this.noteEntryC != null && this.noteEntryC != "")
            {
                var numEntry = parseInt(this.noteEntryC);
                if(numEntry < this.contentMinValue || numEntry > this.contentMaxValue){
                    this.noteError = "La note de contenu doit être comprise entre " + this.contentMinValue + " et " + this.contentMaxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntryC = this.previousEntryC;
                    });
                }
                else
                {
                    this.previousEntryC = this.noteEntryC;
                }
            }
       },
       penalties_change(){
            var ret = 0;
            var cnt = 0;
            var zero = false;
            if(this.penaltiesSet.absenceDebut) { ret += 5; cnt++; }
            if(this.penaltiesSet.absenceFin) { ret += 5; cnt++; }
            if(this.penaltiesSet.depassementTpsPrepa) {ret += 5; cnt++; }
            if(this.penaltiesSet.violDureeMini) { ret+= 5; cnt++; }
            if(this.penaltiesSet.crash) { ret+= 10; cnt++; }
            
            if(this.penaltiesSet.contacts && this.penaltiesSet.contacts > 0) { ret+= this.penaltiesSet.contacts; cnt++; }

            if(this.penaltiesSet.sortieTerrain) { ret+=15; cnt++; }

            this.penaltiesSet.total = ret;
            this.penaltiesSet.count = cnt;
            this.penaltiesSet.zero = zero;
       },
       /*cartouche_change(newVal){
        this.cartouche = newVal;
       },*/
       cartouche_change(){
        console.log('cartouche change', this.cartouche);
       },
       validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            var numEntryE = parseInt(this.noteEntryE);
            var numEntryC = parseInt(this.noteEntryC);
            var grossTotal = ((numEntryE * this.executionPound) + (numEntryC * this.contentPound)); /// (this.executionPound + this.contentPound);
            var total = this.penaltiesSet.zero ? 0 : grossTotal - this.penaltiesSet.total;
            if(total < 0) total = 0;
            var exec = this.penaltiesSet.zero ? 0 : numEntryE - this.penaltiesSet.total;
            if(exec < 0) exec = 0;
            var content = this.penaltiesSet.zero ? 0 : numEntryC - this.penaltiesSet.total;
            if(content < 0) content = 0

            console.log("penaltiesSet", this.penaltiesSet, grossTotal, total,);
            this.$emit('change', { grossExecution: numEntryE, grossContent: numEntryC, execution: exec, content: content, grossTotal: grossTotal, total: total, penalties: this.penaltiesSet, cartouche: this.cartouche });
            this.showConfirmationModal = false;
        },
        cancel_click(){
            this.$emit('cancel');
        },
        focus(){
            this.$refs.executionNote.focus();
        }
    },
    mounted(){
        this.focus();
    }
}
</script>

<style scoped>
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
    table{ font-size:0.8rem; border-collapse: collapse; }
    table td { border:solid 1px darkgray;}

</style>
