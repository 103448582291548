<template>
  <div class="buttons-container">
<!--      <slot name="left"></slot>
      <slot></slot>
      <slot name="right"></slot>-->
    <div class="actions left">
      <slot name="left"></slot>
    </div>
    <div class="actions">
      <slot></slot>
    </div>
    <div class="action right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default
{
}
</script>

<style scoped>
  div.buttons-container{
    width: 100%;
    display: block;
    /*flex-direction: row;*/
    align-content: space-between;
    align-items: center;
  }
  div.actions{
    /*min-width: 33%;*/
    display: flex;
    /*flex-direction: row;*/
    align-content: center;
    align-items: center;
    float:center;
  }
  div.action.left{
    min-width: 33%;
    display: flex;
    /*flex-direction: row;*/
    align-content: flex-start;
    align-items: center;
    float: left;
  }
  div.action.right{
    min-width: 33%;
    display: flex;
    /*flex-direction: row;*/
    align-content: center;
    align-items: flex-end;
    float:right;
  }
</style>
