<template>
    <div v-if="edtPenalties.version==1">
        <table class="note-editor-dialog">
            <tr class="even"><td>dépassement du temps de préparation</td><td class="setter"><b-checkbox v-model="edtPenalties.depassementTpsPrepa" switch size="lg" :disabled="readonly" @change="emit_change" /></td>
            <!--<td rowspan="8" style="font-size: 1.1rem; color:red; font-weight:bolder; text-align:center; vertical-align: top; min-width:40px; max-width:40px;">
                <div style="border:solid 1px red;">{{ totalPenalties }}</div>
            </td>-->
            </tr>
            <tr class="odd"><td>absence de début</td><td class="setter"><b-checkbox v-model="edtPenalties.absenceDebut" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>

            <tr class="even"><td>absence de fin</td><td class="setter"><b-checkbox v-model="edtPenalties.absenceFin" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="odd"><td>violation de durée minimum</td><td class="setter"><b-checkbox v-model="edtPenalties.violDureeMini" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="even"><td colspan="2">violation de durée maximum => <b>Out automatique</b></td></tr>
            <tr class="odd"><td>crash <info-helper>Avec recours au ramasseur à la demande du compétiteur.</info-helper></td><td class="setter"><b-checkbox v-model="edtPenalties.crash" switch :disabled="readonly" size="lg" @change="emit_change"/></td></tr>
            <tr class="even">
                <td>Contacts accidentels 
                    <span style="font-size:1.3rem">
                    <b-badge href="#" v-show="edtPenalties.contacts > 0" pill variant="primary" @click="decrementContacts()" >{{ edtPenalties.contacts }}</b-badge>
                    </span>
                </td>
                <td class="setter">
                    <b-button variant="outline-secondary" @click="incrementContacts()"  size="sm">+</b-button>
                    <!---<info-helper size="lg" >
                        <b-button variant="outline-secondary" @click="decrementContacts()">-</b-button>
                    </info-helper>-->
                </td>
            </tr>
            <tr class="odd"><td>Absence de musique</td><td><b-checkbox v-model="edtPenalties.absenceMusique" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>
            <tr class="even"><td>Mauvaise qualité des enregistrements</td><td><b-checkbox v-model="edtPenalties.mauvaiseQualiteSon" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>
            <tr class="odd"><td>Voler/se déplacer au dessus des limites (DT)<info-helper>Pénalité sur décision du Directeur de Terrain (à appliquer uniquement en cas de demande de sa part).</info-helper></td><td class="setter"><b-checkbox v-model="edtPenalties.sortieTerrain" switch :disabled="readonly" size="lg" @change="emit_change" /></td></tr>
        </table>
    </div>
</template>

<script>
import InfoHelper from '@/components/InfoHelper.vue'

export default{
  components: { InfoHelper },
    props:{
        value:{ type:Object, required:true},
        readonly:{ type:Boolean, default: false},
    },
    data(){
        return {
            edtPenalties: this.value,
        }
    },
    methods:{
        incrementContacts(){
            this.edtPenalties.contacts++;
            this.emit_change();
        },
        decrementContacts(){
            if(!this.edtPenalties) this.edtPenalties.contacts = 0; else if(this.edtPenalties.contacts > 0) this.edtPenalties.contacts--;
            this.emit_change();
        },
        emit_change(){
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
    table{ }
    td{ padding-left: 10px; padding-right: 5px;}
    td.setter{ text-align: center;}
    tr.even{ background-color:white;}
    tr.odd{ background-color:whitesmoke;}
</style>